const businessGuideNavigationsStepsEnum = {
  CATEGORY_CONCEPT: 1,
  CATEGORY_RESEARCH: 2,
  CATEGORY_SETUP: 3,
  CATEGORY_PROJECTIONS: 4,

  // Concept
  SECTION_IDEA: 1,
  SECTION_PRODUCT: 2,
  SECTION_PROMOTION: 3,
  SECTION_DISTRIBUTION: 4,
  SECTION_PARTNERS: 5,
  // Research
  SECTION_MARKET: 6,
  SECTION_CUSTOMERS: 7,
  SECTION_COMPETITORS: 8,
  SECTION_SWOT_ANALYSIS: 9,
  SECTION_MARKET_POTENTIAL: 10,
  // Set Up
  SECTION_STARTUP_COSTS: 11,
  SECTION_FINANCING: 12,
  SECTION_MANAGEMENT_TEAM: 13,
  SECTION_STAFF_AND_PAYROLL: 14,
  // Projections
  SECTION_REVENUE_STREAMS: 15,
  SECTION_DIRECT_COSTS: 16,
  SECTION_MARKETING_BUDGET: 17,
  SECTION_OTHER_OVERHEADS: 18,
  SECTION_PROFIT_AND_CASH_FLOW: 19
}

export { businessGuideNavigationsStepsEnum as default }
