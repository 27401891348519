<template>
  <div class="idea-score-checkmark-text d-flex align-items-center">
    <!--    <div>-->
    <!--      <ib-checkmark class="mr-3" />-->
    <!--    </div>-->
    <p v-if="text" class="m-0 text">
      {{ text }}
    </p>
    <div v-if="hasTextSlot" class="text">
      <slot name="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IdeaScoreCheckmarkText',

  props: {
    text: {
      type: String,
      default: null
    }
  },

  computed: {
    hasTextSlot () {
      return !!this.$slots.text
    }
  }
}
</script>

<style scoped lang="scss">
.idea-score-checkmark-text {
  .text {
    font-size: 16px;
    text-align: center;
  }
}
</style>
