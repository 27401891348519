<template>
  <ib-card class="card-wrapper p-3 w-100 d-flex justify-content-center align-items-center flex-column text-center" :animation="false">
    <div
      v-if="isPlaceholder"
      class="w-100"
    >
      <div class="stripe stripe-medium stripe-bold my-2" />
      <div class="stripe stripe-small mb-4" />
      <ib-progress-dashboard
        :percentage="0"
      />
      <div class="stripe stripe-large mt-4 mb-2" />
      <div class="stripe stripe-medium mb-2" />
      <div class="stripe stripe-small mb-2" />
    </div>
    <div v-else class="d-flex flex-column h-100 justify-content-between mx-2 w-100">
      <div>
        <h3 class="my-0">
          {{ $t('pages.businessGuide.navigation.ideaScore') }}
        </h3>
        <p class="o-50 mt-0">
          {{ idea.name }}
        </p>
        <ib-progress-dashboard
          :percentage="percentage"
          :value="score"
          :additional-text="scoreShortDescription"
          @finished-animation="onFinishedAnimations"
        />
        <p v-if="!moreDetails" :class="{'invisible': !showScoreLongDescription}">
          {{ scoreLongDescription }}
        </p>
      </div>
      <div v-if="!moreDetails" class="mx-2 mb-3">
        <ib-button variant="white" class="w-100" :disabled="score === 0" @click="toggleMoreDetails">
          {{ $t('pages.businessGuide.validation.ideaScore.viewScoreDetails') }}
        </ib-button>
      </div>
      <div
        v-else
        class="score-category-wrapper"
      >
        <div
          v-for="category in scoreCategories"
          :key="category.id"
          class="score-category d-flex justify-content-between py-2 px-2"
        >
          <span>{{ $t(getCategoryTitle(category.id)) }}</span>
          <span>{{ category.score }}</span>
        </div>
        <div
          class="score-total d-flex justify-content-between py-2 px-2 cursor-pointer"
          @click="toggleMoreDetails"
        >
          <span>{{ $t('pages.businessGuide.validation.ideaScore.finalScore') }}</span>
          <span>{{ score }}</span>
        </div>
      </div>
    </div>
  </ib-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import businessGuideNavigationsSteps from '@/data/businessGuideNavigationsSteps'

export default {
  name: 'IdeaScoreProgressCard',

  data () {
    return {
      businessGuideNavigationsSteps,
      moreDetails: false,
      showScoreLongDescription: false,
      showViewScoreDetailsBtn: false
    }
  },

  computed: {
    ...mapGetters('idea', {
      ideaScore: 'getIdeaScore'
    }),

    ...mapState({
      idea: state => state.idea
    }),

    score () {
      return (this.ideaScore && this.ideaScore.calculation) ? this.ideaScore.calculation.total : 0
    },

    progress () {
      return (this.ideaScore && this.ideaScore.progress) ? this.ideaScore.progress : null
    },

    scoreCategories () {
      return (this.ideaScore && this.ideaScore.calculation) ? this.ideaScore.calculation.categories : []
    },

    percentage () {
      return this.score * 10
    },

    isPlaceholder () {
      return this.score === 0 && this.progress !== 100
    },

    scoreShortDescription () {
      const desc = this.scoreDescription

      return this.$t(`pages.businessGuide.validation.ideaScore.scoreShortDescription.${desc}`)
    },

    scoreLongDescription () {
      const desc = this.scoreDescription

      return this.$t(`pages.businessGuide.validation.ideaScore.scoreLongDescription.${desc}`)
    },

    scoreDescription () {
      if (this.score === 0) {
        return 'notDefined'
      } else if (this.score < 3) {
        return 'veryPoor'
      } else if (this.score < 5) {
        return 'poor'
      } else if (this.score < 6) {
        return 'fair'
      } else if (this.score < 7) {
        return 'good'
      } else if (this.score < 8) {
        return 'veryGood'
      } else if (this.score < 9) {
        return 'excellent'
      }

      return 'exceptional'
    }
  },

  methods: {
    toggleMoreDetails () {
      this.moreDetails = !this.moreDetails
    },

    onFinishedAnimations () {
      setTimeout(() => { this.showScoreLongDescription = true }, 500)
    },

    getCategoryTitle (categoryId) {
      const categoryKey = Object.keys(businessGuideNavigationsSteps).find(key => businessGuideNavigationsSteps[key].id === categoryId)

      return businessGuideNavigationsSteps[categoryKey].name
    }
  }
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  box-sizing: border-box;
}
.stripe {
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-height: 10px;
  border-radius: 3px;
  height: auto;
  background-color: #E5E9F2;

  &-small {
    width: 20%;
    max-width: 70px;
  }

  &-medium {
    width: 40%;
    max-width: 150px;
  }

  &-large {
    width: 60%;
    max-width: 220px;
  }

  &-bold {
    height: 20px;
  }
}

.score-category-wrapper {
  :first-child {
    padding-top: 0 !important;
  }
}

.score-category {
  border-bottom: 1px solid $color-text-very-pale-gray;
  font-size: 14px;
}

.score-total {
  color: $color-white;
  background-color: $color-green;
  border-radius: 4px;
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
}
</style>
